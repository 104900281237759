<template>
  <div id="category-list">
    <div id="breadcrumb">
      <div class="breadcrumb-con">
        <p>您的位置：</p>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item to="/Home">首页</el-breadcrumb-item>
          <el-breadcrumb-item>全部商品</el-breadcrumb-item>
        </el-breadcrumb>
        <svg-icon icon-class="ic-close" @click="$router.push('/Home')" />
      </div>
    </div>
    <div class="category-selection">
      <div class="brand">
        <div class="left">
          <h3>品牌</h3>
        </div>
        <div class="right">
          <ul ref="Ul">
            <li
              v-for="(list, index) in Brand"
              :key="index"
              @click="getBrandGoods(list.id, index)"
              :title="list.brandName"
              :class="[
                onBrand === index ? 'liOn' : '',
                liWidth ? 'liwidth' : '',
              ]"
            >
              {{ list.brandName }}
            </li>
          </ul>
          <div class="search-box" v-if="Brand.length > 18">
            <button class="more btn" @click="more">
              <svg-icon
                icon-class="ic-arrow-a"
                :class="[isMore ? 'close' : 'open']"
              />更多
            </button>
          </div>
        </div>
      </div>
      <div class="time">
        <div class="left">
          <h3>最新上架</h3>
        </div>
        <div class="right">
          <ul>
            <li
              v-for="(list, index) in time"
              :key="index"
              @click="day(list)"
              :class="[
                onTime === time[index] ? 'liOn' : '',
                liWidth ? 'liwidth' : '',
              ]"
            >
              {{ list }}天以内
            </li>
          </ul>
          <!-- <button class="more"><svg-icon icon-class="ic-arrow-a" />更多</button> -->
        </div>
      </div>
      <div class="time">
        <div class="left">
          <h3>产地</h3>
        </div>
        <div class="right">
          <ul>
            <li
              :class="noLimit ? 'liOn' : ''"
              @click="[(noLimit = true), (onCity = '')]"
            >
              不限
            </li>
            <li
              v-for="(list, index) in placeOfOrigin"
              :key="index"
              @click="city(list)"
              :class="[
                onCity === placeOfOrigin[index] ? 'liOn' : '',
                liWidth ? 'liwidth' : '',
              ]"
            >
              {{ list }}
            </li>
          </ul>
          <!-- <button class="more"><svg-icon icon-class="ic-arrow-a" />更多</button> -->
        </div>
      </div>
      <!-- <div class="trait">
        <div class="left">
          <h3>特征1</h3>
        </div>
        <div class="right">
          <ul>
            <li>特征值1</li>
            <li>特征值2</li>
            <li>特征值3</li>
            <li>特征值n</li>
          </ul>
        </div>
      </div>
      <div class="trait">
        <div class="left">
          <h3>特征2</h3>
        </div>
        <div class="right">
          <ul>
            <li>特征值1</li>
            <li>特征值2</li>
            <li>特征值3</li>
            <li>特征值n</li>
          </ul>
          <button class="more"><svg-icon icon-class="ic-arrow-a" />更多</button>
        </div>
      </div>
      <div class="trait">
        <div class="left">
          <h3>特征3</h3>
        </div>
        <div class="right">
          <ul>
            <li>特征值1</li>
            <li>特征值2</li>
            <li>特征值3</li>
          </ul>
          <button class="more"><svg-icon icon-class="ic-arrow-a" />更多</button>
        </div>
      </div> -->
    </div>
    <goods-list :brandId="BrandId" :time="onTime" :city="onCity" />
  </div>
</template>

<script>
import GoodsList from "@/components/GoodsList";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  name: "category-list",
  components: { GoodsList, Breadcrumb },
  data() {
    return {
      Brand: "",
      BrandId: "",
      onBrand: -1,
      onTime: "",
      isMore: false,
      time: [7, 30, 90],
      liWidth: false,
      placeOfOrigin: ["北京", "上海", "广东", "福建", "湖南"],
      onCity: "",
      noLimit: true,
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.getAllBrand();
      },
    },
  },
  created() {
    this.getAllBrand();
  },
  // mounted() {
  //   console.log(this.$refs.Ul);
  //   this.$refs.Ul.style.height = "58px";
  // },
  methods: {
    //品牌
    async getAllBrand() {
      const data = await this.$API.brand.AllBrand({
        object: {
          brandName: "",
          categoryId: null,
          name: this.$route.query.like,
        },
        pageNum: 0,
        pageSize: 0,
      });
      this.Brand = data.data.data;
      if (this.Brand.length < 19) {
        this.$refs.Ul.style.height = "29px";
      } else {
        this.$refs.Ul.style.height = "58px";
        this.liWidth = true;
      }
    },
    //选择品牌
    getBrandGoods(id, i) {
      if (this.BrandId === id) {
        this.BrandId = "";
        this.onBrand = "";
      } else {
        this.BrandId = id;
        this.onBrand = i;
      }
    },
    //选择时间
    day(day) {
      if (this.onTime === day) {
        this.onTime = "";
      } else {
        this.onTime = day;
      }
    },
    city(city) {
      this.onCity = city;
      this.noLimit = false;
    },
    //品牌更多按钮展开
    more() {
      let h = this.$refs.Ul.scrollHeight;
      if (this.isMore) {
        this.$refs.Ul.style.height = "58px";
        this.isMore = false;
      } else {
        this.$refs.Ul.style.height = h + "px";
        this.isMore = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#category-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-top: 1px solid var(--subjectColor);
  #breadcrumb {
    background: #fff;
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    .breadcrumb-con {
      width: 1200px;
      height: 55px;
      display: flex;
      align-items: center;
      p {
        font-size: 14px;
        color: #999;
      }
      ::v-deep.el-breadcrumb {
        span {
          color: #999;
        }
        .el-breadcrumb__inner {
          font-weight: normal;
        }
        .el-breadcrumb__inner:hover {
          color: var(--subjectColor);
        }
      }
      ::v-deep.el-breadcrumb .el-breadcrumb__item:last-child span {
        color: #333;
        cursor: default;
      }
      .svg-icon {
        margin-left: 4px;
        font-size: 12px;
        color: #333;
        cursor: pointer;
      }
      .svg-icon:hover {
        color: #000;
      }
    }
  }
  .category-selection {
    width: 1200px;
    border: 1px solid #eeeeee;
    margin-bottom: 20px;
    div:last-child {
      border: none;
    }
    .brand,
    .time,
    .trait {
      border-bottom: 1px solid #eeeeee;
      display: flex;
      .left {
        width: 80px;
        padding: 12px 16px;
        background: #f5f6f8;
        line-height: 16px;
        h3 {
          float: right;
          font-size: 12px;
          font-weight: bold;
          color: #333333;
        }
      }
      .right {
        width: 1118px;
        display: flex;
        padding: 6px 10px;
        justify-content: space-between;

        ul {
          display: flex;
          flex-wrap: wrap;
          // width: 780px;
          overflow: hidden;
          transition: all 0.3s;
          li {
            width: 106px;
            padding: 6px 0;
            margin-right: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
            color: #666666;
            line-height: 16px;
            cursor: pointer;
            -moz-user-select: none;
            -ms-user-select: none;
            -webkit-user-select: none;
            user-select: none;
          }
          li:hover {
            color: var(--subjectColor);
          }
          .liOn {
            color: var(--subjectColor);
            font-weight: bold;
          }
          li:nth-child(9n) {
            margin: 0;
          }
          .liwidth {
            width: 98px;
          }
        }
        .search-box {
          display: flex;
          // .search {
          //   display: flex;
          //   width: 180px;
          //   height: 28px;
          //   border: 1px solid #dddddd;
          //   input {
          //     width: 130px;
          //     text-indent: 10px;
          //     border: none;
          //     outline: none;
          //     font-size: 12px;
          //   }
          //   input::-webkit-input-placeholder {
          //     /* WebKit browsers */
          //     color: #ddd !important;
          //   }
          //   input::-moz-placeholder {
          //     /* Mozilla Firefox 4 to 18 */
          //     color: #ddd !important;
          //   }
          //   input::-moz-placeholder {
          //     /* Mozilla Firefox 19+ */
          //     color: #ddd !important;
          //   }
          //   input::-ms-input-placeholder {
          //     /* Internet Explorer 10+ */
          //     color: #ddd !important;
          //   }
          //   button {
          //     background: #f5f6f8;
          //     width: 48px;
          //     border: none;
          //     font-size: 12px;
          //     color: #00a86b;
          //     cursor: pointer;
          //   }
          // }
          .btn {
            width: 60px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #dddddd;
            display: flex;
            align-items: center;
            justify-content: center;
            // margin-left: 12px;
            cursor: pointer;
            .svg-icon {
              color: #989898;
              font-size: 10px;
              margin-right: 4px;
              transition: all 0.3s;
            }
            .multi-select {
              transform: rotate(-45deg);
            }
            .close {
              transform: rotate(-180deg);
            }
            .open {
              transform: rotate(0);
            }
          }
        }
      }
    }
  }
  .more {
    width: 60px;
    height: 28px;
    background: #ffffff;
    border: 1px solid #dddddd;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    cursor: pointer;
    .svg-icon {
      color: #989898;
      font-size: 10px;
      margin-right: 4px;
    }
  }
}
</style>